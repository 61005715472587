import React from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Logo from "../logo";
import { Search } from "../Search";
import "./styles.css";

export const extensionLink =
  "https://chrome.google.com/webstore/detail/textcortex-ai-writing-ass/hahkojdegblcccihngmgndhdfheheofe";

export const Navigation = () => {
  const location = useLocation();

  return (
    <nav className="nav-container">
      <div className="nav-content">
        <div className="nav-left-content">
          <NavLink className="logo" to="/api/">
            <Logo />
          </NavLink>
          {/* <NavLink to="/stoplight-project">Compare</NavLink> */}
          <div>
            <Search projectIds={["cHJqOjE3Mzg5NQ"]} />
          </div>
        </div>
        <div className="nav-links">
          <NavLink className="link active-link" to="/api">
            Docs
          </NavLink>{" "}
          <a
            href="https://textcortex.com/text-generation-api"
            className="link"
            target={"_blank"}
          >
            Plans
          </a>{" "}
          <a target={"_blank"} className="link" href={extensionLink}>
            Install Extension
          </a>
        </div>
      </div>
    </nav>
  );
};
