import type { NodeSearchResult } from "@stoplight/elements-dev-portal";
import {
  Search as ElementsSearch,
  useGetNodes,
  useGetWorkspace,
} from "@stoplight/elements-dev-portal";
import * as React from "react";
import { AiOutlineSearch, AiOutlineMacCommand } from "react-icons/ai";
import "./styles.css";

export type SearchProps = {
  projectIds: string[];
};

let docsUrl = "https://docs.textcortex.com/api";
// let docsUrl = 'https://localhost:4200';
// docsUrl = process.env.REACT_APP_DOCS_URL || docsUrl;

export const Search = ({ projectIds }: SearchProps) => {
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const { data } = useGetNodes({
    search,
    projectIds,
  });
  const { data: workspace } = useGetWorkspace({
    projectIds,
  });

  const handleKeyPress = React.useCallback((event) => {
    if ((event.metaKey || event.ctrlKey) && event.code === "KeyK") {
      setOpen(true);
    }
    if (event.code === "Escape") {
      setOpen(false);
      setSearch("");
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const handleClose = () => {
    setOpen(false);
    setSearch("");
  };

  const handleClick = (data: NodeSearchResult) => {
    let uri = data.uri;
    // remove /openapi.json from uri beginning
    if (uri.startsWith("/openapi.json")) {
      uri = uri.slice("/openapi.json".length);
    }
    // Replace "/~1" with "/"
    uri = uri.replace(/\/~1/g, "/");
    // Replace "~1" with "-
    uri = uri.replace(/~1/g, "-");
    // Remove /components from uri beginning
    if (uri.startsWith("/components")) {
      uri = uri.slice("/components".length);
    }
    window.location.href = `${docsUrl}${uri}`;
    // window.location.href = `https://${workspace?.workspace.slug}.stoplight.io/docs/${data.project_slug}${data.uri}`;
  };

  return (
    <>
      <div className="search-container">
        <div onFocus={() => setOpen(true)} contentEditable className="input">
          <div>
            <AiOutlineSearch className="icon" size={14} color="white" />
            <p style={{ marginLeft: 4 }}>Search</p>
          </div>
          <div className="ctrl-icon">
            <AiOutlineMacCommand className="icon" size={14} color="white" />
            <span style={{ marginTop: 2, marginLeft: 2 }}>+ K</span>
          </div>
        </div>
      </div>
      <ElementsSearch
        search={search}
        onSearch={setSearch}
        onClick={handleClick}
        onClose={handleClose}
        isOpen={open}
        searchResults={data}
      />
    </>
  );
};
