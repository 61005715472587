import "@stoplight/elements/styles.min.css";

import { API } from "@stoplight/elements";
import React from "react";

export const StoplightAPI: React.FC = () => {
  let apiUrl = "https://staging-s647te7twq-ez.api.textcortex.com/v1/openapi.json";
  apiUrl = process.env.REACT_APP_API_SPEC_URL || apiUrl;
  return (
    <API
      basePath="/api"
      apiDescriptionUrl={apiUrl}
      // apiDescriptionUrl="https://raw.githubusercontent.com/stoplightio/Public-APIs/master/reference/zoom/openapi.yaml"
    />
  );
};
